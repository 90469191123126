import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77cd36e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "height", "width", "loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Image", {
      UseDefaultMinHeight: _ctx.useDefaultMinHeight,
      Fallback: _ctx.useFallback,
    }])
  }, [
    (_ctx.useFallback)
      ? _renderSlot(_ctx.$slots, "fallback", { key: 0 }, () => [
          _createVNode(_component_InlineSVG, {
            src: require('@/assets/svg/image-placeholder.svg')
          }, null, 8, ["src"])
        ], true)
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          ref: "image",
          src: _ctx.src,
          height: _ctx.dimensions.height || null,
          width: _ctx.dimensions.width || null,
          style: _normalizeStyle(_ctx.imgStyle),
          loading: _ctx.loading,
          onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageError && _ctx.onImageError(...args))),
          onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageLoaded && _ctx.onImageLoaded(...args)))
        }, null, 44, _hoisted_1)),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 2 }))
      : _createCommentVNode("", true)
  ], 2))
}